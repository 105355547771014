//
// forms.scss
//

.form {
    overflow: hidden;
    background-color: $white;
    top: 0rem;
    position: absolute !important;
    z-index: $zindex-offcanvas;
    right: 0rem;
    bottom: 0rem;
    // width: 80rem;
    border: 1px solid;
    border-color: $primary;
    display: grid;
    flex-direction: column;
    height: 100vh;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 100%;
}

.form-control {
    &::placeholder {
        color: shade-color($gray-500, 7%);
    }
    &:disabled,
    &[readonly] {
        background-color: shade-color($gray-200, 7%);
        border-color: shade-color($gray-200, 7%);
        opacity: 1;
    }
}

// Form-control light
.form-control-light {
    background-color: $input-light-bg !important;
    border-color: $input-light-bg !important;
}


// Form elements (Color and Range)
input.form-control[type="color"],
input.form-control[type="range"] {
    min-height: 39px;
}


// Custom select
.custom-select.is-invalid, .form-control.is-invalid,.custom-select:invalid, .form-control:invalid,
.custom-select.is-valid, .form-control.is-valid, .custom-select:valid,.form-control:valid {
    &:focus {
        box-shadow: none !important;
    }
}

// Comment box
.comment-area-box {
    .form-control {
        border-color: $gray-300;
        border-radius: $input-border-radius $input-border-radius 0 0;
    }
    .comment-area-btn {
        background-color: $gray-100;
        padding: 10px;
        border: 1px solid $gray-300;
        border-top: none;
        border-radius: 0 0 $input-border-radius $input-border-radius;
    }
}

// Search box 
.search-bar {

    .form-control {
        padding-left: 40px;
        padding-right: 20px;
        border-radius: 30px;
    }

    span {
        position: absolute;
        z-index: 10;
        font-size: 16px;
        line-height: $input-height;
        left: 13px;
        top: -2px;
        color: $text-muted;
    }
}

// Show Password
.password-eye {
    &:before {
        font-family: feather!important;
        content: "\e86a";
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        vertical-align: middle;
        line-height: 1.2;
        font-size: 16px;
    }
}

.show-password {
    .password-eye {
        &:before {
            content: "\e86e";
        }
    }  
}

@mixin variant-outline($bg) {
    color: $bg;
    border: 1px solid rgba($bg, $btn-soft-bg-opacity), 0.01;
    box-shadow: none;
}

@each $color,
$value in $theme-colors {
    .outline-soft-#{$color} {
        @include variant-outline($value);
    }
}