// 
// tables.scss
//

th {
    font-weight: $font-weight-bold;
}

tr {
    user-select: none; 
}

//Table centered (Custom)
.table-centered {
    td,th {
        vertical-align: middle !important;
        
    }
}


// Table 

.table-nowrap {
    th,td {
        white-space: nowrap;
    }
}

// Custom table components (Custom)
.table {
    .table-user {
        img {
            height: 30px;
            width: 30px;
        }
    }
}

.action-icon {
    color: $gray-600;
    font-size: 1.2rem;
    display: inline-block;
    padding: 0 3px;

    &:hover {
        color: $light-blue-200;
    }
}

@mixin tr-variant-soft($bg, $color) {
    background-color: rgba($bg, $btn-soft-bg-opacity);
    color: $color;
    border-color: rgba($color, $btn-soft-bg-opacity);

    &:focus {
        box-shadow: none;
    }

    &:hover,
    &:focus {
        background-color: fade-in(rgba($bg, $btn-soft-bg-opacity), .05);
        color: $color;
    }
}

@each $color, $value in $theme-colors {
    .tr-soft-#{$color} {
        @include tr-variant-soft($value, $value);
    }
}

// Lighten tr
@mixin tr-variant-light($bg) {
    box-shadow: none;
    &:hover,
    &:focus {
        background-color: fade-in(rgba($bg, $btn-soft-bg-opacity), 0.01);
    }
}

// Lighten tr (soft)

@each $color,
$value in $theme-colors {
    .hover-soft-#{$color} {
        @include tr-variant-light($value);
    }
}