.MuiDataGrid-footerContainer {
  display: none;
}

.MuiDataGrid-root {
  height: inherit;
}

.MuiDataGrid-virtualScroller {
  height: fit-content !important;
}

.MuiDataGrid-main {
  overflow: visible;
  overflow-x: hidden !important;
}

.MuiDataGrid-columnHeaders {
  position: sticky !important;
  background-color: aliceblue;
  z-index: 2;
}

.MuiDataGrid-virtualScroller {
  margin-top: 0px !important
}