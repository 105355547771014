.MuiDataGrid-footerContainer {
  display: none;
}

.MuiDataGrid-root {
  height: inherit;
}

.MuiDataGrid-virtualScroller {
  height: fit-content !important;
}

.MuiDataGrid-main {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.MuiDataGrid-columnHeaders {
  position: sticky !important;
  background-color: aliceblue;
  z-index: 2;
}

/* .MuiDataGrid-virtualScroller {
  margin-top: 0px !important;
  height: 74vh !important;
  overflow: hidden;
}

.MuiDataGrid-virtualScrollerContent {
  height: unset !important;
} */
